import { Box, Image, Input, Modal, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { AddressFormater } from "helpers/global";
import { addIconCircle } from "helpers/images";
import React, { useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { useFieldArray, useForm } from "react-hook-form";

export default function NewForm() {
    const { control, setValue, watch } = useForm();
    const billing = watch("billing_address") || [];
    const billingLength = billing.length === 0 ? 0 : billing.length - 1;
    return (
        <form>
            <AddressFieldPopup
                label={"Biling Address"}
                name={`billing_address.${billingLength}`}
                mainName={"billing_address"}
                control={control}
                setValue={setValue}
                watch={watch}
            />
        </form>
    );
}

function AddressFieldPopup({ name, control, setValue, label, watch, mainName }) {
    const [opened, { open, close }] = useDisclosure(false);
    const { append } = useFieldArray({
        name: mainName,
        control,
    });

    return (
        <>
            <Address
                name={name}
                control={control}
                setValue={setValue}
                label={label}
                watch={watch}
                open={open}
                plusFuntion={() => {
                    open();
                }}
            />

            <Modal opened={opened} onClose={close}>
                <Address
                    name={"sub_address"}
                    control={control}
                    setValue={setValue}
                    label={label}
                    watch={watch}
                    open={open}
                    defaultfield={true}
                    plusFuntion={(e) => {
                        append(e);
                        setValue("sub_address", "");
                    }}
                />

                {watch(mainName)?.length >= 1 && (
                    <Box
                        display={"flex"}
                        sx={{
                            flexDirection: "column",
                        }}
                    >
                        {watch(mainName).map((addres) => {
                            return (
                                <div>
                                    <span>{addres?.address}</span>
                                    <span>{addres?.unit}</span>
                                </div>
                            );
                        })}
                    </Box>
                )}
            </Modal>
        </>
    );
}

function Address({ label, setValue, name, watch, plusFuntion, defaultfield }) {
    const [addressValue, setAddressValue] = useState({
        address: undefined,
        unit: undefined,
    });
    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_API_MAP_ENDPOINT,
        onPlaceSelected: (place) => {
            const address = AddressFormater(place);
            setValue(name, {
                ...address,
                unit: watch(`${name}[unit]`),
            });
            setAddressValue((state) => ({
                ...state,
                ...address,
            }));
        },

        options: {
            types: ["address"],
            componentRestrictions: { country: "ca" },
        },
    });
    return (
        <>
            <Input.Wrapper label={label} required={false} mx="auto">
                <TextInput
                    ref={ref}
                    // value={addressValue.address}
                    onChange={(e) => {
                        // !defaultfield &&
                        // 	setAddressValue((state) => ({
                        // 		...state,
                        // 		address: e.target.value,
                        // 	}));
                    }}
                    // ref={}
                    // defaultValue={field.value}
                    // placeholder={'Address'}
                    // className="mantine-input-style-copy"
                    // // {...sampleAddress}
                    // options={{
                    // 	types: ["address"],
                    // 	componentRestrictions: { country: "ca" },
                    // }}
                    // apiKey={process.env.REACT_APP_API_MAP_ENDPOINT}
                    // onPlaceSelected={(address) => {
                    // 	const addressObj = AddressFormater(address);
                    // 	field.onChange(addressObj);
                    // 	onChange && onChange(addressObj);
                    // }}

                    // aria-invalid={error}
                />
                {/* {error && <Input.Error>{error.message}</Input.Error>} */}
            </Input.Wrapper>
            <TextInput
                name=""
                value={addressValue.unit}
                onChange={(e) => {
                    const unitName = `${name}[unit]`;
                    setValue(unitName, e.target.value);
                    setAddressValue((state) => ({
                        ...state,
                        unit: e.target.value,
                    }));
                }}
            />

            <Image
                src={addIconCircle}
                width={"20px"}
                height={"20px"}
                onClick={() => {
                    !defaultfield &&
                        setAddressValue({
                            unit: undefined,
                            address: undefined,
                        });
                    plusFuntion(watch(name));
                }}
            />
        </>
    );
}
